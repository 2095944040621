import { inject } from "fw";
import { PopoverController, PopoverCoordinator } from "service/popover";
import { correctPopoverPosition } from "helpers/auto-complete";

@inject
export class EditorPopover {
  private variables: string[] = null;
  private selectedItem: number = 0;

  constructor(private controller: PopoverController<any>, private coordinator: PopoverCoordinator) { }

  activate(params: { variables: string[] }) {
    this.variables = params.variables;

    const keypress = this.onKeyPress.bind(this);

    this.coordinator.waitForClose().then(() => {
      window.removeEventListener("keyup", keypress, { capture: true });
    });

    setTimeout(() => {
      window.addEventListener("keyup", keypress, { capture: true });
    });

    correctPopoverPosition();
  }

  updateSelection(index) {
    this.selectedItem = index;
  }

  onKeyPress(event) {
    const key = event.keyCode, enter = 13, up = 38, down = 40;
    const enterPressed = key == enter && this.selectedItem in this.variables;

    if (this.selectedItem == null) return;
    if (enterPressed || key == up || key == down) event.preventDefault();
    if (enterPressed) this.selectVariable(this.variables[this.selectedItem], false);
    else if (key == up) this.selectedItem = Math.max(this.selectedItem - 1, 0);
    else if (key == down) this.selectedItem = Math.min(this.selectedItem + 1, this.variables.length - 1);
  }

  selectVariable(variable, clicked: boolean = true) {
    this.controller.close(false, { variable: variable, clicked: clicked });
  }
}
